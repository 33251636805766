import { render, staticRenderFns } from "./epidemicInfo.vue?vue&type=template&id=5e054798&scoped=true&"
import script from "./epidemicInfo.vue?vue&type=script&lang=js&"
export * from "./epidemicInfo.vue?vue&type=script&lang=js&"
import style0 from "./epidemicInfo.vue?vue&type=style&index=0&id=5e054798&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e054798",
  null
  
)

export default component.exports