//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//获取排行版列表
const getCommentPraiseRankingListURL =
  "/gateway/hc-neighbour/topic/getCommentPraiseRankingList";
//获取积分商城详情
const getTopicDetailURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
// //话题类型
// const getInitInfoURL = "//gateway/hc-neighbour/talent/getInitInfo";
//话题姓名
const getTalentUserListURL = `/gateway/hc-neighbour/talent/getTalentUserList`;
//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
// 发送积分
const doTopicURL = "/gateway/hc-neighbour/dailyPerf/doTopic";
//获取广播站签收人列表
const getSigninUsersUrl = `/gateway/hc-neighbour/topic/getSigninUsers`;
// 取消参与
const cancelSigninUrl = `/gateway/hc-neighbour/topic/cancelSignin`;

//防疫表单列表
const epidemicPreventUrl = `/gateway/hc-serve/manageapi/forms/FormsList`;
//表单修改
const epidemicPreventEditUrl = `/gateway/hc-serve/manageapi/forms/compileForms`;
//表单新增
const epidemicPreventAddUrl = `/gateway/hc-serve/manageapi/forms/addForms`;
//表单详情
const epidemicPDetailUrl = `/gateway/hc-serve/manageapi/forms/formsDetails`;
//表单删除
const epidemicDeleteUrl = `/gateway/hc-serve/manageapi/forms/deleteForms`;
//表单问题删除
const epidemicProDeleteUrl = `/gateway/hc-serve/manageapi/forms/deleteFormsProblem`;

//防疫提交记录
const epidemicPostUrl = `/gateway/hc-serve/manageapi/forms/commitRecordList`;
//防疫提交记录详情
const epidemicPostDetailUrl = `/gateway/hc-serve/manageapi/forms/commitRecordDetails`;
//防疫提交记录导出
const epidemicOutUrl = `/gateway/hc-serve/manageapi/forms/commitRecordExport`;
//删除提交记录
const deleteEpidemicUrl = `/gateway/hc-serve/manageapi/forms/deleteCommitRecord`;
export {
  getTopicListURL,
  getTopicDetailURL,
  addTopicURL,
  updateTopicURL,
  getCommunityListUrl,
  // getInitInfoURL,
  getCommentPraiseRankingListURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
  doTopicURL,
  getSigninUsersUrl,
  cancelSigninUrl,
  epidemicPreventUrl,
  epidemicPostUrl,
  epidemicPostDetailUrl,
  epidemicOutUrl,
  deleteEpidemicUrl,
  epidemicPreventEditUrl,
  epidemicPDetailUrl,
  epidemicDeleteUrl,
  epidemicProDeleteUrl,
  epidemicPreventAddUrl,
};
