import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

const sortField = [
  // {
  //   value: undefined,
  //   label: "正常",
  // },
  {
    value: "NEW",
    label: "最新",
  },
  {
    value: "HOT",
    label: "最热",
  },
];

const { map: sortFieldMap, setOps: sortFieldOps } = mapHelper.setMap(sortField);

// 性别
const sexStatus = [
  {
    value: 1,
    label: "男",
  },
  {
    value: 2,
    label: "女",
  },
];
const { map: sexStatusMap, setOps: sexStatusOps } = mapHelper.setMap(sexStatus);
// 表单类型
const formType = [
  {
    value: 1,
    label: "自主上报",
  },
  {
    value: 2,
    label: "外来人口登记",
  },
  {
    value: 3,
    label: "其他",
  },
];
const { map: formTypeMap, setOps: formTypeOps } = mapHelper.setMap(formType);
// 表单发布类型
const formPostType = [
  {
    value: 1,
    label: "已发布",
  },
  {
    value: 0,
    label: "待发布",
  },
];
const { map: formPostTypeMap, setOps: formPostTypeOps } =
  mapHelper.setMap(formPostType);
// 问题类型	问题类型：1，2，3
const formProType = [
  {
    value: 1,
    label: "单选",
  },
  {
    value: 2,
    label: "文本输入",
  },
  {
    value: 3,
    label: "日期选择",
  },
];
const { map: formProTypeMap, setOps: formProTypeOps } =
  mapHelper.setMap(formProType);
export {
  statusMap,
  setStatusOps,
  isTop,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
  sexStatus,
  sexStatusMap,
  sexStatusOps,
  formType,
  formTypeMap,
  formPostType,
  formPostTypeMap,
  formProType,
  formProTypeMap,
};
